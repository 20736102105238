%bar {
  height: 2px;
  position: fixed;
  top: 0;
  background: blue;
  z-index: 9999;
}

@keyframes loading-bar-anim {
  from {
    width: 0;
  }
  to {
    width: 80%;
  }
}

@keyframes loading-bar-anim-done {
  from {
    width: 80%;
  }
  to {
    width: 100%;
  }
}

.loading-bar {
  @extend %bar;
  width: 80%;
  animation-name: loading-bar-anim;
  animation-duration: 3s;
}

.loading-bar-done {
  @extend %bar;
  width: 100%;
  animation-name: loading-bar-anim-done;
  animation-duration: .5s;
}

.loading-bar-hidden {
  display: none;
}
