@import 'src/scss/wlsSettings.scss';

.description {
  display: flex;
  gap: 7px;
  margin-top: -16px;
}

.label {
  color: grey;
  font-size: 14px;
  font-weight: 400;

}

.selector_label {
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  color: $primaryColor;

  -moz-user-select: none;
  -webkit-user-select: none;

  &:after {
    content: '';
    margin-left: 5px;
    border-left: 4px solid #0000;
    border-right: 4px solid #0000;
    border-top: 4px dashed;
    border-top: 4px solid \9
  ;
    display: inline-block;
    height: 0;
    vertical-align: middle;
    width: 0;
  }
}
