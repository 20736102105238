header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 28px;
  height: 70px;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
}

.company-name {
  font-size: 24px;
  font-weight: bold;
  margin-right: 30px;
  white-space: nowrap;
  cursor: default;
}

.company-type-toggles {
  flex-grow: 2;
  input {
    display: none;
  }

  input:checked + label {
    color: $mainTextColor;
  }

  label {
    font-size: 18px;
    font-weight: bold;
    color: $secondaryTextColor;
    cursor: pointer;
    &:first-of-type {
      margin-right: 24px;
    }
  }
}

.logout {
  font-size: 16px;
  color: $secondaryTextColor;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: $mainTextColor;
    cursor: pointer;
  }
}


@media screen and (max-width: 560px) {
  header {
    padding: 10px 5px;
  }
}