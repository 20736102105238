.tools {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 40px;

  p {
    font-size: 14px;
    margin-bottom: 16px;
    white-space: nowrap;
  }
}

.text-action {
  text-decoration: underline;
  cursor: pointer;
}

.tool-endpoint {
  width: 266px;
  height: 74px;
}

.tool-daterange {
  width: 420px;
  margin: 0 40px;

  .DayPickerInput input {
    width: 200px;
    height: 40px;
    border: 1px solid $borderColorInput;
    border-radius: $borderRadius;
    padding: 14px 11px;
    font-size: 14px;
  }

  .DayPickerInput:first-of-type {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1096px) {
  
  .tool-endpoint {
    width: 420px;
    margin-right: 40px;
  }

  .tool-daterange {
    margin: 0
  }

  .tools {
    .button-report {
      margin: 20px 0;
    }
  }
}

@media screen and (max-width: 1015px) {
  .tools {
    display: block;
  }
}

@media screen and (max-width: 560px) {
  .tools {
    width: 100%;
  }

  .tool-endpoint,
  .tool-daterange {
    width: 100%;
    margin: 0 0 20px 0 !important;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .DayPickerInput {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .DayPickerInput input {
    width: 100% !important;
  }
}