%general-button {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: $borderRadius;
  cursor: pointer;
  float: right;
  margin-left: 10px;
}

.button-report {
  @extend %general-button;
  width: 180px;
  background: $primaryColor;
  color: #fff;
  float: left;
}

.button-card {
  @extend %general-button;
  min-width: 150px;
  border: 1px solid $primaryColor;
  color: $mainTextColor;
  position: relative;
  padding: 0 10px;
  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background: url('../img/download-icon.svg') no-repeat center bottom;
  }
}

.button-card-copy {
  &:before {
    background: url('../img/copy-icon.svg') no-repeat center bottom;
    background-size: contain;
  }  
}

@media screen and (max-width: 660px) {
  .button-card {
    width: auto;
    padding: 0 10px;
    font-size: 10px;
  }
}
