.card {
  border: 1px solid $borderColorCard;
  padding: 20px;
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
}

.card-header {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin-bottom: 20px;
  white-space: nowrap;
}

.card-content {
  width: 100%;
  height: 100%;
  position: relative;
}


@media screen and (max-width: 1015px) {
  .card {
    margin-bottom: 40px;
  }
}
