.login {
  width: 352px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  label {
    font-size: 16px;
    margin-bottom: 15px;
    display: inline-block;
  }

  input {
    width: 100%;
    height: 40px;
    padding: 14px 11px;
    border: 1px solid $borderColorInput;
    border-radius: $borderRadius;
    margin-bottom: 35px;
  }

  .button-report {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    margin: 0;
  }
}

.login-header {
  display: flex;
  margin-bottom: 22px;
  text-align: left;
  font-size: 20px;
  height: 26px;
  line-height: 26px;

  &:before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-right: 10px;
    background: url('../img/logo.png') no-repeat center;
    background-size: contain;
  }
}