@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
.performance_description__2UAdD {
  display: flex;
  grid-gap: 7px;
  gap: 7px;
  margin-top: -16px; }

.performance_label__1eWNL {
  color: grey;
  font-size: 14px;
  font-weight: 400; }

.performance_selector_label__3VyYs {
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  color: #8526E0;
  -moz-user-select: none;
  -webkit-user-select: none; }
  .performance_selector_label__3VyYs:after {
    content: '';
    margin-left: 5px;
    border-left: 4px solid #0000;
    border-right: 4px solid #0000;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    display: inline-block;
    height: 0;
    vertical-align: middle;
    width: 0; }

/* DayPicker styles */
.DayPicker {
  display: inline-block;
  font-size: 1rem; }

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  padding-bottom: 1em;
  -webkit-user-select: none;
  user-select: none; }

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  user-select: none; }

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer; }

.DayPicker-NavButton:hover {
  opacity: 0.8; }

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC"); }

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg=="); }

.DayPicker-NavButton--interactionDisabled {
  display: none; }

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left; }

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em; }

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em; }

.DayPicker-WeekdaysRow {
  display: table-row; }

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em; }

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none; }

.DayPicker-Body {
  display: table-row-group; }

.DayPicker-Week {
  display: table-row; }

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer; }

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer; }

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default; }

.DayPicker-Footer {
  padding-top: 0.5em; }

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer; }

/* Default modifiers */
.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700; }

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default; }

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */ }

/* Example modifiers */
.DayPicker-Day--sunday {
  background-color: #F7F8F8; }

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #4A90E2;
  color: #F0F8FF; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA; }

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF; }

/* DayPickerInput */
.DayPickerInput {
  display: inline-block; }

.DayPickerInput-OverlayWrapper {
  position: relative; }

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); }

.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff; }

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em; }

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  margin-left: -160px; }

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em; }

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em; }

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  margin-left: -160px; }

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0; }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0; }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }

.Toastify__toast--rtl {
  direction: rtl; }

.Toastify__toast--default {
  background: #fff;
  color: #aaa; }

.Toastify__toast--info {
  background: #3498db; }

.Toastify__toast--success {
  background: #07bc0c; }

.Toastify__toast--warning {
  background: #f1c40f; }

.Toastify__toast--error {
  background: #e74c3c; }

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start; }

.Toastify__close-button--default {
  color: #000;
  opacity: 0.3; }

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1; }

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left; }

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards; }

.Toastify__progress-bar--controlled {
  transition: transform .2s; }

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right; }

.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  animation-name: Toastify__flipOut; }

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }

/*# sourceMappingURL=ReactToastify.css.map */
body {
  width: 100%;
  height: 100%; }

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  color: #3D3D3D;
  box-sizing: border-box; }

main {
  padding: 0 68px;
  max-width: 1200px;
  margin: auto;
  overflow: hidden; }

.row {
  width: 100%; }
  .row .col {
    width: 50%;
    float: left;
    padding: 0 20px; }
    .row .col:nth-child(odd) {
      padding-left: 0; }
    .row .col:nth-child(even) {
      padding-right: 0; }
  .row:after {
    display: block;
    clear: both;
    content: ''; }

.nodata {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

@media screen and (max-width: 1015px) {
  .col {
    width: 100% !important;
    float: none !important;
    padding: 0 !important; } }

@media screen and (max-width: 560px) {
  main {
    max-width: 98%;
    padding: 0;
    margin: auto; } }

.Toastify__toast-body {
  font-weight: bold;
  color: #fff; }

.bold {
  font-weight: bold; }

.login {
  width: 352px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .login label {
    font-size: 16px;
    margin-bottom: 15px;
    display: inline-block; }
  .login input {
    width: 100%;
    height: 40px;
    padding: 14px 11px;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    margin-bottom: 35px; }
  .login .button-report {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    margin: 0; }

.login-header {
  display: flex;
  margin-bottom: 22px;
  text-align: left;
  font-size: 20px;
  height: 26px;
  line-height: 26px; }
  .login-header:before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-right: 10px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAqCAYAAADMKGkhAAAAAXNSR0IArs4c6QAABVZJREFUWEfNmWtsFFUUx39n26IFU8pTrCINKjFgrfgBJKCCAUMQMBDEGkUTxUrSx+62GpUgLiaIGnB2W4lKNMYvoGnUiBiDQSWihocBRcrjC4qgUSLPaIHa7jEzbbG73b0zs1OU+bh7zv/+5syZe849IxqjL38iZLraSYrFmYz/ufyoNVxFPpNQbkEZDQxBKALyUVoQTqAcQtiFsJm/2CVr+NvrWqJhvkcYDLSlORWSZKs0MNurmG2nNUwln2qUqUA/z77KfoS3EN4Qi+Nufjb4MYSBWQx3SJxxbiKdwKMJEUeY5sU+q43wG0mWSII3TTq9Aq61VCJYCH0DQXd3Fproz0KJcTqTZmBwjfAc8EyvAXcXUr6hjdmymmPp+oHANcxShGUXBPpf0c0IM9I3iZzBNcp8lHc9Qh9E+RLlIJBEuAbhdmCkJ3/hFbGoScmkXF5OrWM4ynaUYcaFhaMIT1LEWonRmpIFMfpwknuBF4AS1xtQpkuCjV12OUVcI7wOVBoXU/ZQwGxZyY8mO63lakJsAMpc4LfxCxOliXbbzje4VlNGHtsRLjVsaUcp4DZ5iQOukbT3/ie4jjYnlcxPUJknCd7LDTxCHAi7RHuh2z6c7q9hHrGLj4vux5Jgpm9wjVHESXYDIwwL7KSY8RLrUYnNTJUU0I8dKOVZDZXTKGXSwM++UkXrmUY7n7q8kBGxSHhJkR5Rj1KPstJF/36xWOsPPMwyhKUG4XP0odxrbvcAr2IMBXznNGLZrs6t0R94lI/QjhzLeCm7GcBYiZHMKeJ2uhSyG+F6g/9miTPFM7jGCHHC6SRvMESjSSzm5wLd5aO1rCfELIPGPoq5yTt4FYPowx7jliW8KBZPBQKP8irKIoPGr7Qw2jt4NSXksxfobxBdLHFWBAKP8DzwtEHjFGcZ5R08QinKPmPhgVqJ0xgQPAY8a3iPTnDGD3iUK0myt/P4lVnXPgA0sDwguAVEDBpHOO0nVRYxlEKaUeeYl21XWSUJHg8EHuZthAcNGs20MNZ7qtRwCXlO1RxlEF0vce4OBB5hCzDJoLFJ4kzzDG4LaYQvgMmGiB8gSbk0ci4XeLV3rgKagcsNa1iSoM4feJRGlGojlDJOEuzICdxbS3GfWLzjF7wCZZ0L1HKJsyQn8DCvITxm3ApDlMnLHPYLbu8sPyAMMIgfpoWbZQ1/+IHXekbQxk7DqASEDWJ1VFVf4E6eh1mHUOGSLr53Fw2zBuFRo26SudLAB7mB1zGFJJ+7RlOpkIS3w7TW8TBJ8wAIZSsDmNjVwPmOeOfu8iG4jOaUsyg10mA+1WiEKnBOVdlb2Y4ozZA4n3QFLDfwjr75a5e+pWMNZSNCIy1so4TjNCMMYyAhJiDOEfAO16fXW+MJh8ceu4Wc0763SzkJ/I4gKEMRir05soliZkmMs93tc4p4l4CGWYEEa2NdXvLPEOZJ3LnplCsQeGe+X4jZoT07SdDO4mxVODC4A2+P42C1sQHzmBfYY+Y2HpJG86G8V8A7I38F6gxAF7j07G63YI/qjgB7gC0I22hlb/rEVjTizDIG9fgiIRTaTmIxz22l7v9rlJEkeQCYgzAGKPDjn9FWnY8P+4GdhNhOEe+L2u1qtmsQmj6s9Aqh95BHKaW0MgbhWuesKs4gaQIw3KtOBrufKKY880erAKpurs7HslPMRJ3ybrfIboUnVVJx2on/HDwlrToKmX3ascfNprFel5s9Wx8vFt/+r+Dn60GUQtSpoAtQ7jR0n19JnFttv4sCPOUpVDKYy5iOMpckU9Iq7PkpwkUHnnITNQwhxGSEOcCN5HGXrOKQbfMPhHMdJnE3DZAAAAAASUVORK5CYII=) no-repeat center;
    background-size: contain; }

.table {
  width: 100%;
  table-layout: fixed; }
  .table th {
    text-align: left; }
  .table td, .table th {
    width: 25%;
    margin-bottom: 20px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .table td:first-child, .table th:first-child {
      width: 50%;
      padding-right: 10px; }
    .table td:last-child, .table th:last-child {
      text-align: right; }
  .table td:nth-child(2), .table th:nth-child(2) {
    font-weight: bold; }

.dailyTable {
  width: 100%;
  table-layout: fixed; }
  .dailyTable th {
    text-align: left; }
  .dailyTable td, .dailyTable th {
    width: 25%;
    margin-bottom: 20px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .dailyTable td:first-child, .dailyTable th:first-child {
      width: 50%;
      padding-right: 10px; }
    .dailyTable td:last-child, .dailyTable th:last-child {
      text-align: right; }
  .dailyTable tr:last-child {
    font-weight: bold; }

.card {
  border: 1px solid #E9E9E9;
  padding: 20px;
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden; }

.card-header {
  margin-bottom: 20px;
  white-space: nowrap; }

.card-content {
  width: 100%;
  height: 100%;
  position: relative; }

@media screen and (max-width: 1015px) {
  .card {
    margin-bottom: 40px; } }

.button-report, .button-card {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin-left: 10px; }

.button-report {
  width: 180px;
  background: #8526E0;
  color: #fff;
  float: left; }

.button-card {
  min-width: 150px;
  border: 1px solid #8526E0;
  color: #3D3D3D;
  position: relative;
  padding: 0 10px; }
  .button-card:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background: url(/static/media/download-icon.cb9580e0.svg) no-repeat center bottom; }

.button-card-copy:before {
  background: url(/static/media/copy-icon.e25578b0.svg) no-repeat center bottom;
  background-size: contain; }

@media screen and (max-width: 660px) {
  .button-card {
    width: auto;
    padding: 0 10px;
    font-size: 10px; } }

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 28px;
  height: 70px;
  max-width: 1200px;
  margin: auto;
  overflow: hidden; }

.company-name {
  font-size: 24px;
  font-weight: bold;
  margin-right: 30px;
  white-space: nowrap;
  cursor: default; }

.company-type-toggles {
  flex-grow: 2; }
  .company-type-toggles input {
    display: none; }
  .company-type-toggles input:checked + label {
    color: #3D3D3D; }
  .company-type-toggles label {
    font-size: 18px;
    font-weight: bold;
    color: #AFAFAF;
    cursor: pointer; }
    .company-type-toggles label:first-of-type {
      margin-right: 24px; }

.logout {
  font-size: 16px;
  color: #AFAFAF;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap; }
  .logout:hover {
    color: #3D3D3D;
    cursor: pointer; }

@media screen and (max-width: 560px) {
  header {
    padding: 10px 5px; } }

.tools {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 40px; }
  .tools p {
    font-size: 14px;
    margin-bottom: 16px;
    white-space: nowrap; }

.text-action {
  text-decoration: underline;
  cursor: pointer; }

.tool-endpoint {
  width: 266px;
  height: 74px; }

.tool-daterange {
  width: 420px;
  margin: 0 40px; }
  .tool-daterange .DayPickerInput input {
    width: 200px;
    height: 40px;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    padding: 14px 11px;
    font-size: 14px; }
  .tool-daterange .DayPickerInput:first-of-type {
    margin-right: 20px; }

@media screen and (max-width: 1096px) {
  .tool-endpoint {
    width: 420px;
    margin-right: 40px; }
  .tool-daterange {
    margin: 0; }
  .tools .button-report {
    margin: 20px 0; } }

@media screen and (max-width: 1015px) {
  .tools {
    display: block; } }

@media screen and (max-width: 560px) {
  .tools {
    width: 100%; }
  .tool-endpoint,
  .tool-daterange {
    width: 100%;
    margin: 0 0 20px 0 !important; }
    .tool-endpoint:after,
    .tool-daterange:after {
      content: '';
      display: block;
      clear: both; }
  .DayPickerInput {
    width: 100% !important;
    margin-bottom: 20px !important; }
  .DayPickerInput input {
    width: 100% !important; } }

.loading-bar, .loading-bar-done {
  height: 2px;
  position: fixed;
  top: 0;
  background: blue;
  z-index: 9999; }

@keyframes loading-bar-anim {
  from {
    width: 0; }
  to {
    width: 80%; } }

@keyframes loading-bar-anim-done {
  from {
    width: 80%; }
  to {
    width: 100%; } }

.loading-bar {
  width: 80%;
  animation-name: loading-bar-anim;
  animation-duration: 3s; }

.loading-bar-done {
  width: 100%;
  animation-name: loading-bar-anim-done;
  animation-duration: .5s; }

.loading-bar-hidden {
  display: none; }

.custom-tooltip {
  background: #fff;
  padding: 10px 12px;
  color: #3D3D3D;
  border: 1px solid #D4D4D4; }

