@mixin reset-table {
  width: 100%;
  table-layout: fixed;

  th {
    text-align: left;
  }

  td, th {
    &:first-child {
      width: 50%;
      padding-right: 10px;
    }

    width: 25%;
    margin-bottom: 20px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;


    &:last-child {
      text-align: right;
    }
  }
}

.table {
  @include reset-table;

  td, th {
    &:nth-child(2) {
      font-weight: bold;
    }
  }
}

.dailyTable {
  @include reset-table;

  tr:last-child {
    font-weight: bold;
  }
}
