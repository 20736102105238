@import "../../node_modules/react-day-picker/lib/style";
@import "../../node_modules/react-toastify/dist/ReactToastify";
//colors

@import 'wlsSettings.scss';
$mainTextColor: #3D3D3D;
$secondaryTextColor: #AFAFAF;
$borderColorInput: #D4D4D4;
$borderColorCard: #E9E9E9;

$borderRadius: 4px;

//break points

$largeBP: 1200px;
$mediumLargeBP: 1024px;
$mediumSmallBP: 768px;
$smallBP: 414px;


body {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  font-family: $font_family;
  color: $mainTextColor;
  box-sizing: border-box;
}

main {
  padding: 0 68px;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
}

.row {
  width: 100%;

  .col {
    width: 50%;
    float: left;
    padding: 0 20px;

    &:nth-child(odd){
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-right: 0;
    }
  }

  &:after {
    display: block;
    clear: both;
    content: '';
  }
}

.nodata {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

@media screen and (max-width: 1015px) {
  .col {
    width: 100% !important;
    float: none !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 560px) {
  main {
    max-width: 98%;
    padding: 0;
    margin: auto;
  }
}

.Toastify__toast-body {
  font-weight: bold;
  color: #fff;
}

.bold {
  font-weight: bold;
}

@import 'login';
@import 'table';
@import 'card';
@import 'buttons';
@import 'header';
@import 'tools';
@import 'loading';
@import 'custom_tooltip';
